<template>
  <div>
    <h2 v-if="invitations.length" class="content__title title">Приглашения</h2>
    <MemberList v-if="invitations.length" :members="invitations" />
    <div v-else class="slug">
      <div class="slug__img">
        <img src="@/assets/img/team/team-slug.svg" alt="Slug">
      </div>
      <p class="slug__text">Вы не отправляли приглашений</p>
    </div>
  </div>
</template>

<script>
import MemberList from '@/components/MemberList'

export default {
  name: 'TeamInvites',
  components: {
    MemberList,
  },
  data() {
    return {
      invitations: [],
    }
  },
  mounted() {
    this.fetchMembers()
  },
  methods: {
    fetchMembers() {
      this.$store.dispatch('team/GET_MEMBERS', { id: this.$route.params.id, status: 'invited' })
        .then(response => {
          this.invitations = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
          this.$router.push({name: 'my-teams'})
        })
    },
  },
}
</script>